// import { cloneDeep, uniqueId } from 'lodash-es';
// import {
//   productService,
//   classifyService,
//   deviceClassifyService,
//   outputService,
//   backendService,
//   platformPrintingMaterialService,
//   platformBackendMaterialService
// } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
// import { DeviceClassifyResource, ProductEdit } from '@/resource/model';
// import { messageError, translation } from '@/utils';
// import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
// import { ChargeUnitEnum } from '@/resource/enum';
// import { CascaderOption } from 'element-ui/types/cascader-panel';

@Component({})
export default class AddProduct extends mixins(DialogMixin) {
  // @Prop({
  //   required: false,
  //   type: Object,
  //   default: () => {
  //     return null;
  //   }
  // })
  // public product!: ProductEdit | null;
  // public isInitalized = false;
  // public classifies: Array<CascaderOption> = [];
  // public deviceClassifies: Array<DeviceClassifyResource> = [];
  // public printingCraftList: Array<{ label: string; value: number }> = [];
  // public printingMaterialList: Array<{ label: string; value: number }> = [];
  // public backendCraftList: Array<{ label: string; value: number }> = [];
  // public backendMaterialList: Array<{ label: string; value: number }> = [];
  // public title = '';
  // public productForm: Partial<ProductEdit> & {
  //   categoryIdList: Array<number>;
  // } = {
  //   id: undefined,
  //   categoryIdList: [],
  //   categoryId: undefined,
  //   secondCategoryId: undefined,
  //   deviceCategoryId: undefined,
  //   remark: '',
  //   priceUnit: 1,
  //   productCode: '',
  //   printingMaterialId: undefined,
  //   printingCraftsId: undefined,
  //   backendDataList: [this.defaultBackendData()]
  // };
  // public resourceFormRules = {
  //   categoryIdList: [
  //     {
  //       required: true,
  //       validator: (rule: any, value: string, callback: Function): void => {
  //         if (!value || value.length === 0) {
  //           callback(new Error(translation('product.selectClassify')));
  //           return;
  //         }
  //         callback();
  //       },
  //       trigger: 'change'
  //     }
  //   ],
  //   deviceCategoryId: [
  //     {
  //       required: true,
  //       validator: (rule: any, value: string, callback: Function): void => {
  //         if (!value) {
  //           callback(new Error(translation('product.selectDeviceClassify')));
  //           return;
  //         }
  //         callback();
  //       },
  //       trigger: 'change'
  //     }
  //   ],
  //   priceUnit: [
  //     {
  //       required: true,
  //       validator: (rule: any, value: string, callback: Function): void => {
  //         if (!value) {
  //           callback(new Error(translation('product.selectCalcUnit')));
  //           return;
  //         }
  //         callback();
  //       },
  //       trigger: 'change'
  //     }
  //   ],
  //   printingCraftsId: [
  //     {
  //       required: true,
  //       validator: (rule: any, value: string, callback: Function): void => {
  //         if (!value) {
  //           callback(new Error(translation('product.selectPrintingCraft')));
  //           return;
  //         }
  //         callback();
  //       },
  //       trigger: 'change'
  //     }
  //   ]
  // };
  // public submitLoading = false;
  // private operationType: 'add' | 'edit' = 'add';
  // public get classifyOption(): Array<CascaderOption> {
  //   return this.classifies;
  // }
  // public get deviceClassifyOption(): Array<DeviceClassifyResource> {
  //   return this.deviceClassifies;
  // }
  // public get calcUnitOption(): Array<{ label: string; value: number }> {
  //   const options = [];
  //   for (const key in ChargeUnitEnum) {
  //     if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
  //       options.push({
  //         label: translation('chargeUnit.' + ChargeUnitEnum[key]),
  //         value: Number(key)
  //       });
  //     }
  //   }
  //   return options;
  // }
  // public dialogOpen(): void {
  //   this.init();
  //   if (!this.product) {
  //     this.operationType = 'add';
  //     this.title = 'product.addProduct';
  //     return;
  //   }
  //   this.operationType = 'edit';
  //   this.title = 'product.editProduct';
  //   this.$nextTick(() => {
  //     Object.assign(this.productForm, this.product);
  //     this.productForm.categoryIdList = [Number(this.product?.categoryId)];
  //     this.productForm.backendDataList = this.productForm.backendDataList ?? [this.defaultBackendData()];
  //     if (this.product?.secondCategoryId) {
  //       this.productForm.categoryIdList.push(Number(this.product?.secondCategoryId));
  //     }
  //   });
  // }
  // public dialogClosed(): void {
  //   this.$emit('dialog-closed');
  //   (this.$refs.productForm as Form).resetFields();
  //   this.productForm.backendDataList = [this.defaultBackendData()];
  // }
  // public onSubmit(): void {
  //   (this.$refs.productForm as Form).validate(async (valid: boolean) => {
  //     if (!valid) {
  //       return;
  //     }
  //     this.setLoading(true);
  //     try {
  //       // 过滤空后道工艺 + 材料数据
  //       const backendList = [];
  //       for (const item of this.productForm.backendDataList ?? []) {
  //         if (!item.craftsId && !item.materialId) {
  //           continue;
  //         }
  //         const existItem = backendList.find(
  //           existItem => existItem.craftsId === item.craftsId && existItem.materialId === item.materialId
  //         );
  //         if (!existItem) {
  //           backendList.push(item);
  //         }
  //       }
  //       const cloneObj = cloneDeep(this.productForm);
  //       cloneObj.backendDataList = backendList;
  //       if (this.operationType === 'add') {
  //         await productService.post({ ...cloneObj } as any);
  //         Message.success(translation('operationRes.addSuccess'));
  //         this.$emit('add-success');
  //         this.closeDialog();
  //         return;
  //       }
  //       await productService.put({ ...cloneObj } as any);
  //       Message.success(translation('operationRes.editSuccess'));
  //       this.$emit('edit-success', this.productForm);
  //       this.closeDialog();
  //     } catch (error) {
  //       messageError(error);
  //     } finally {
  //       this.setLoading(false);
  //     }
  //   });
  // }
  // @Watch('productForm.categoryIdList')
  // public handleCategoryChange(value: Array<number>): void {
  //   this.productForm.categoryId = value[0];
  //   this.productForm.secondCategoryId = value[1];
  // }
  // /**
  //  * 新增后道工艺+材料控件
  //  */
  // public handleAddItem(): void {
  //   this.productForm.backendDataList?.push(this.defaultBackendData());
  // }
  // /**
  //  * 删除后道工艺+材料控件
  //  */
  // public handleDeleteItem($event: Event, index: number): void {
  //   this.productForm.backendDataList?.splice(index, 1);
  // }
  // /**
  //  * 初始化dialog所需数据
  //  */
  // private init(): void {
  //   // 判断是否已初始化
  //   if (this.isInitalized) return;
  //   this.loadEnableProductClassifyData();
  //   this.loadEnableDeviceClassifyData();
  //   this.loadPrintingCraftData();
  //   this.loadBackendCraftData();
  //   this.loadPrintingMaterialData();
  //   this.loadBackendMaterialData();
  //   this.isInitalized = true;
  // }
  // private loadEnableDeviceClassifyData(): void {
  //   deviceClassifyService
  //     .listAllEnable()
  //     .then(res => {
  //       this.deviceClassifies = res;
  //     })
  //     .catch(error => {
  //       messageError(error);
  //     });
  // }
  // private loadEnableProductClassifyData(): void {
  //   classifyService
  //     .getTreeData()
  //     .then(res => {
  //       this.classifies = classifyService.handleCascaderOption(res as any);
  //     })
  //     .catch(error => {
  //       messageError(error);
  //     });
  // }
  // /**
  //  * 载入输出工艺数据
  //  */
  // private async loadPrintingCraftData(): Promise<void> {
  //   try {
  //     const craftList = await outputService.getAllEnableList();
  //     this.printingCraftList = craftList.map(craft => {
  //       return {
  //         label: craft.craftsName,
  //         value: craft.id
  //       };
  //     });
  //   } catch (error) {
  //     messageError(error);
  //   }
  // }
  // /**
  //  * 载入后道工艺数据
  //  */
  // private async loadBackendCraftData(): Promise<void> {
  //   try {
  //     const craftList = await backendService.getAllEnableList();
  //     this.backendCraftList = craftList.map(craft => {
  //       return {
  //         label: craft.craftsName,
  //         value: craft.id
  //       };
  //     });
  //   } catch (error) {
  //     messageError(error);
  //   }
  // }
  // /**
  //  * 载入企业输出材料数据
  //  */
  // private async loadPrintingMaterialData(): Promise<void> {
  //   try {
  //     const materialList = (await platformPrintingMaterialService.listEnable()) || [];
  //     this.printingMaterialList = materialList.map(material => {
  //       return {
  //         label: material.materialName,
  //         value: material.id
  //       };
  //     });
  //   } catch (error) {
  //     messageError(error);
  //   }
  // }
  // /**
  //  * 载入企业输出材料数据
  //  */
  // private async loadBackendMaterialData(): Promise<void> {
  //   try {
  //     const materialList = (await platformBackendMaterialService.listEnable()) || [];
  //     this.backendMaterialList = materialList.map(material => {
  //       return {
  //         label: material.materialName,
  //         value: material.id
  //       };
  //     });
  //   } catch (error) {
  //     messageError(error);
  //   }
  // }
  // private defaultBackendData(): { craftsId?: number; materialId?: number; key: string } {
  //   return {
  //     craftsId: undefined,
  //     materialId: undefined,
  //     key: uniqueId()
  //   };
  // }
}
